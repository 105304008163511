<template>
  <div class="platform-main-page">
    <platform-main-menu :class="menuClasses"></platform-main-menu>
    <header class="background_header cover">
      <img :src="$getAsset('/assets/about/portraitmosaic.jpg')" />
      <div class="splash-overlay"></div>
      <div class="header-content" v-if="featureSwitchesLoadedState">
        <h1 v-if="mainTitle" class="main-title extended">{{ mainTitle }}</h1>

        <ai-search-form v-if="featureSwitchesState.SEARCH_AI"></ai-search-form>
        <search-all-records-form v-else button-label="Search" :allow-empty-submit="true"></search-all-records-form>
      </div>
    </header>

    <xmas-banner-subscription></xmas-banner-subscription>
    <cny-banner-subscription></cny-banner-subscription>

    <div :id="platformFeaturesOverviewID" class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Access the records that matter.</h5>
        <p>
          Zupus packed with family trees and stories. Burial and immigration records from America and Southeast Asia.
          Access the world's largest genealogical collection for Overseas Chinese.
        </p>
        <mcr-button-router-link class="inline" :to="subscriptionPlansRoute">Compare Plans</mcr-button-router-link>
      </div>
      <div class="image_panel_image">
        <img
          width="600"
          height="320"
          :data-src="$getAsset('/assets/services/zupus/dict-preview.png')"
          class="lazyload in-layer"
        />
      </div>
    </div>

    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Grow your Family Tree. Designed for Chinese families.</h5>
        <p>
          Start with what you know. Use our family tree builder to discover the what, when, where, and who of your
          Chinese ancestry.
        </p>
        <mcr-button-router-link class="inline" :to="ftRoute">Build My Tree</mcr-button-router-link>
        <router-link class="demo-link" :to="ftDemoRoute">View Demo</router-link>
      </div>
      <div class="image_panel_image">
        <img
          width="600"
          height="375"
          :data-src="$getAsset('/assets/services/family-tree-software-cropped.png')"
          class="lazyload in-layer ft-image"
        />
      </div>
    </div>

    <div class="image_panel reverse_panel">
      <div class="image_panel_text">
        <h5>Preserve Your Memories.</h5>
        <p>Organize your family history treasures for future generations with our Photos & Files feature.</p>
        <mcr-button-router-link class="inline" :to="myLibraryRoute">Learn More</mcr-button-router-link>
      </div>
      <div class="image_panel_image">
        <img
          width="600"
          height="660"
          :data-src="$getAsset('/assets/services/library_splash2.png')"
          class="lazyload in-layer"
        />
      </div>
    </div>

    <div class="image_panel">
      <div class="image_panel_text">
        <h5>Chinese genealogy is hard. Get expert guidance.</h5>
        <p>
          Get advice from our team of industry-leading genealogists, who have helped thousands discover their Chinese
          roots.
        </p>
        <mcr-button-router-link class="inline" :to="subscriptionPlansRoute">Get Support</mcr-button-router-link>
      </div>
      <div class="image_panel_image">
        <img
          width="600"
          height="660"
          :data-src="$getAsset('/assets/services/support-preview-cropped.png')"
          class="lazyload in-layer"
        />
      </div>
    </div>

    <database-overview></database-overview>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import SearchAllRecordsForm from '@common/elements/forms/SearchAllRecordsForm';
import DatabaseOverview from '@common/elements/main-menu/DatabaseOverview';
import {setHeaderTransparent} from '@common/elements/main-menu/transparentHeader';
import AiSearchForm from '@common/pages/searches/forms/AiSearchForm';
import {getFamilyTreeLibraryRoute, getFamilyTreeRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

import consts from '@/base/utils/consts';

import PlatformMainMenu from '@/base/elements/main-menu/PlatformMainMenu';

import cnyBannerSubscription from '@/components/common/promotions/cnyBannerSubscription';
import xmasBannerSubscription from '@/components/common/promotions/xmasBannerSubscription';

import {DEMO_FAMILY_TREE_ID} from '@/components/modules/familyTree/constants';

export default {
  metaInfo: {
    title: 'Search',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Search for Chinese ancestors in our database of zupu, burials, immigration and more. Build you tree and preserve your discoveries for future generations to come.',
      },
    ],
  },
  mounted() {
    window.addEventListener('scroll', this.handleHeaderTransparent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleHeaderTransparent);
  },
  data() {
    return {
      menuClasses: setHeaderTransparent() ? 'is-transparent' : '',
      myLibraryRoute: getFamilyTreeLibraryRoute(this.$store),
      ftRoute: getFamilyTreeRoute(this.$store),
      subscriptionPlansRoute: {name: 'subscription-plans'},
      ftDemoRoute: {name: 'familytree-details', params: {id: DEMO_FAMILY_TREE_ID}},
      platformFeaturesOverviewID: consts.platformFeaturesOverviewID,
    };
  },
  computed: {
    ...mapGetters(['featureSwitchesState', 'featureSwitchesLoadedState']),
    mainTitle() {
      const ai = 'Explore our growing database of Overseas Chinese records with power of AI';
      const regular = 'Explore our growing database of Overseas Chinese records';
      return this.featureSwitchesState.SEARCH_AI ? ai : regular;
    },
  },
  methods: {
    handleHeaderTransparent() {
      this.menuClasses = setHeaderTransparent() ? 'is-transparent' : '';
    },
  },
  components: {
    DatabaseOverview,
    McrButtonRouterLink,
    PlatformMainMenu,
    SearchAllRecordsForm,
    xmasBannerSubscription,
    cnyBannerSubscription,
    AiSearchForm,
  },
  name: 'PlatformMainPage',
};
</script>

<style lang="scss" scoped>
.platform-main-page {
  .cover {
    min-height: calc(100vh - 100px);
    position: relative;
    height: auto;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .header-content {
      position: relative;
      min-height: 350px;
      padding: 20px 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .splash-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.4);
      -webkit-box-shadow: inset 0 0 120px rgba(black, 0.5);
      box-shadow: inset 0 0 120px rgba(black, 0.5);
      background-image: linear-gradient(180deg, rgba(black, 0.3), rgba(black, 0.3) 15px, rgba(black, 0));
      background-repeat: no-repeat;
      background-size: 100% 200px;
    }

    .main-title {
      margin-bottom: 40px;
    }

    .ai-search-form {
      padding: 10px;
      border-radius: 4px;
      background: $background-light;
      max-width: 660px;
    }

    .surname-search-form {
      max-width: 800px;
    }

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      filter: brightness(80%);
    }
  }

  .image_panel {
    .mcr-button {
      margin-bottom: 28px;
    }

    .demo-link {
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    .image_panel {
      padding-top: 75px;
      padding-bottom: 75px;
    }
    .cover {
      .header-content {
        padding: 90px 50px 20px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-mobile-se) {
    .cover {
      justify-content: flex-end;

      .header-content {
        padding: $main-menu-height-mobile $mobile-se-content-padding;
        min-height: 0;
      }
    }
  }
}

h1 {
  font-size: 42px;
  @media only screen and (max-width: $breakpoint-desktop) {
    font-size: 38px;
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    font-size: 36px;
  }
  @media only screen and (max-width: $breakpoint-mobile-se) {
    font-size: 32px;
  }
  @media only screen and (max-width: 360px) {
    font-size: 26px;
  }
}

.ft-image {
  margin-top: -25px;
}
</style>
